import React  from "react"
import Layout from "../components/layout";
import { Helmet } from "react-helmet"; 
import {  ChevronRightIcon, SunIcon } from "@heroicons/react/solid"; 
import { StaticImage } from "gatsby-plugin-image"; 
import { Link } from "gatsby";
import { Plan } from "../components/Plan";
import { Flats } from "../components/Flats";

const PlankyPage = ({location}) => {
     
  // Move this to it's own component consuming context.
 

  return (
  <>
       <Helmet>
      <meta charSet="utf-8" />
      <title>Plánky | Domy Trio</title> 
    </Helmet>
      <Layout location={location}> 
   

      
<main >
    <div className="mx-auto w-full   relative z-30 ">
       <div className="mx-auto max-w-6xl w-full p-8 lg:px-14 z-auto  backdrop-filter  bg-white bg-opacity-90 relative shadow-md border border-gray-200 ">    
             <svg className="absolute w-60 h-60 text-yellow-400 top-0 right-0 ">
                <polygon points="0,0 300,0 300,300" class="triangle" fill="currentColor" />
                <SunIcon width="50" x="160" y="-70"  fill="#ffffff" />
            </svg>
            <div className="relative">
           <div className="">
               <h1 className="text-xl lg:text-3xl font-merri mb-10 border-b border-yellow-400 pb-3">Plánky domů Trio</h1> 
           </div>

            <div className="grid ">
                <div  >
                    <div className="text-sm py-2 text-gray-600">
                    Bytové domy Trio s osvědčeným dispozičním řešením rozdělené na 3 bytové jednotky s obytným podkrovím jednoduše rozvržené do tvaru písmene L.
                    </div>
                    <div className=" space-y-6 items-center justify-center ">
                        <div>
                        <Link to={'/planky/trio-1'}>
                        <button
                            type="button"
                            className="flex items-center px-6 py-3 border border-transparent shadow-sm w-full  text-base font-medium rounded-md text-white bg-gray-600 hover:bg-yellow-400 focus:outline-none "
                        >
                            <ChevronRightIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                            Trio 1
                        </button>
                        </Link>
                        <Flats trio={1} />
                        </div>
                        <div>
                        <Link to={'/planky/trio-2'}>
                        <button
                            type="button"
                            className="flex items-center px-6 py-3 border border-transparent shadow-sm w-full text-base font-medium rounded-md text-white bg-gray-600 hover:bg-yellow-400 focus:outline-none "
                        >
                            <ChevronRightIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                            Trio 2
                        </button>
                        </Link>
                        <Flats trio={2} />
                        </div>
                        <div>
                        <Link to={'/planky/trio-3'}>
                        <button
                            type="button"
                            className="flex items-center px-6 py-3 border border-transparent shadow-sm w-full text-base font-medium rounded-md text-white bg-gray-600 hover:bg-yellow-400 focus:outline-none "
                        >
                            <ChevronRightIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                            Trio 3
                        </button>
                        </Link>
                        <Flats trio={3} />
                        </div>
                    </div>
                </div>
                <div className="relative w-full max-w-lg mt-5 mx-auto"> 
                    <div className="relative">
                        <Plan />
                    </div>
                </div>
            </div>

                   
        </div>
    </div>

    </div>
  </main>
    <div >
        
  </div>
    </Layout>
  </>
  )
}

export default PlankyPage
 